<template>
  <div class="pays">
    <div class="flex flex-center flex-wrap pays-icon" v-if="from == '1'">
      <img src="../../../assets/img/order/pay_success.png" alt="">
      <div class="pays-icon-title">支付成功</div>
    </div>
    <div class="pays-toast" :class="from == '2' ? 'pays-toast1' : ''">
      感谢您订购了<br/>
      《流媒体营销密码与私域带货实战课程套装》
    </div>
    <div class="pays-info">
      <div class="pays-info-title">请完成以下内容的填写，完成课程报名</div>
      <div class="pays-info-con">
        <div class="flex flex-between align-center pays-info-con-list">
          <div class="pays-info-con-list-name">真实姓名</div>
          <div class="pays-info-con-list-r">
            <input type="text" v-model="nickName" placeholder="请填写真实姓名" class="pays-info-con-list-r-input">
          </div>
        </div>
        <div class="flex flex-between align-center pays-info-con-list">
          <div class="pays-info-con-list-name">联系电话</div>
          <div class="pays-info-con-list-r">
            <input type="text" v-model="phone" placeholder="请填写真实的联系方式，以便老师联系" class="pays-info-con-list-r-input">
          </div>
        </div>
        <div class="flex flex-between align-center pays-info-con-list">
          <div class="pays-info-con-list-name">性别</div>
          <div class="flex flex-right pays-info-con-list-r">
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="flex flex-between align-center pays-info-con-list">
          <div class="pays-info-con-list-name">抖音号</div>
          <div class="pays-info-con-list-r">
            <input type="text" v-model="tiktokNum" placeholder="请填写真实的抖音号" class="pays-info-con-list-r-input">
          </div>
        </div>
        <div class="flex flex-between align-center pays-info-con-list">
          <div class="pays-info-con-list-name">住家区域</div>
          <div class="flex flex-right align-center pays-info-con-list-r">
            <span class="pays-info-con-list-r-span" :class="areaHome != '' ? 'pays-info-con-list-r-span1' : ''" @click="openAreaFunc(1)">{{ areaHome == '' ? '省/市/区' : areaHome }}</span>
            <img src="../../../assets/img/order/right.png" alt="">
          </div>
        </div>
        <div class="flex flex-between align-center pays-info-con-list">
          <div class="pays-info-con-list-name">工作区域</div>
          <div class="flex flex-right align-center pays-info-con-list-r">
            <span class="pays-info-con-list-r-span" :class="workHome != '' ? 'pays-info-con-list-r-span1' : ''" @click="openAreaFunc(2)">{{ workHome == '' ? '省/市/区' : workHome }}</span>
            <img src="../../../assets/img/order/right.png" alt="">
          </div>
        </div>
        <div class="pays-info-con-btn">
          <van-button class="pays-info-con-btn1" round color="#E62129" @click="onSubmit">提交信息</van-button>
        </div>
        <div class="pays-info-con-bo" @click="goOrderListPages">暂时跳过，稍后在订单列表完成填写</div>
      </div>
    </div>
    <!-- 省市区选择 -->
    <van-popup v-model:show="showArea" position="bottom" round>
      <van-area title="城市" :area-list="areaList" @cancel="showArea = false;" @confirm="confirmAreaFunc"/>
    </van-popup>

    <qrService></qrService>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>