import { areaList } from '@vant/area-data';
import qrService from '@/components/qr.vue';
import { addUserInfo } from '@/utils/order.js';
export default {
    components: {
        qrService
    },
    setup() {
        return { areaList };
    },
    data() {
        return {
            showArea: false,
            nickName: '', // 真实姓名
            phone: '', // 联系电话
            sex: '', // 性别，传中文
            tiktokNum: '', // 抖音号
            province: '', // 住家省
            city: '', // 住家市
            county: '', // 住家区
            areaHome: '',
            workProvince: '', // 工作省
            workCity: '', // 工作市
            workCounty: '', // 工作区
            workHome: '',
            areaIdx: '',
            orderId: '',
            from: '', // 来源 1 下单页面  2 订单列表
        }
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.from = this.$route.query.from;
        console.log('orderId', this.orderId)
    },
    methods: {
        // 查看订单
        goOrderPageFunc() {
            this.$router.push({
                path: '/orderDetail'
            })
        },
        // 打开区域选择
        openAreaFunc(idx) {
            this.showArea = true;
            this.areaIdx = idx;
        },
        // 处理区域
        confirmAreaFunc(val) {
            console.log('val111', val);
            if (this.areaIdx == 1) {
                this.province = val[0].name; // 住家省
                this.city = val[1].name; // 住家市
                this.county = val[2].name; // 住家区
                this.areaHome = val[0].name+'/'+val[1].name+'/'+val[2].name;
            } else if (this.areaIdx == 2) {
                this.workProvince = val[0].name; // 工作省
                this.workCity = val[1].name; // 工作市
                this.workCounty = val[2].name; // 工作区
                this.workHome = val[0].name+'/'+val[1].name+'/'+val[2].name;
            }
            this.showArea = false;
        },
        onSubmit() {
            if (this.nickName == '') {
                this.$toast.fail({
                    message: '请填写真实姓名'
                });
                return false;
            }
            if (this.phone == '') {
                this.$toast.fail({
                    message: '请填写真实的联系方式'
                });
                return false;
            } else if (!/^1\d{10}$/.test(this.phone)) {
                this.$toast.fail({message: '请输入正确的联系方式'});
                return false;
            }
            if (this.sex == '') {
                this.$toast.fail({
                    message: '请选择性别'
                });
                return false;
            }
            if (this.tiktokNum == '') {
                this.$toast.fail({
                    message: '请填写抖音号'
                });
                return false;
            }
            if (this.areaHome == '') {
                this.$toast.fail({
                    message: '请选择居住区域'
                });
                return false;
            }
            if (this.workHome == '') {
                this.$toast.fail({
                    message: '请选择工作区域'
                });
                return false;
            }
            let data = {
                orderId: this.orderId, // 订单编号
                nickName: this.nickName, // 真实姓名
                phone: this.phone, // 联系电话
                sex: this.sex, // 性别，传中文
                tiktokNum: this.tiktokNum, // 抖音号
                province: this.province, // 住家省
                city: this.city, // 住家市
                county: this.county, // 住家区
                workProvince: this.workProvince, // 工作省
                workCity: this.workCity, // 工作市
                workCounty: this.workCounty, // 工作区
               // 省市区传中文
            }
            addUserInfo(data).then(res => {
                console.log('补充信息', res)
                this.$toast.success({
                    message: '信息提交成功！'
                });
                setTimeout(() => {
                    this.goOrderListPages();
                }, 1000)
            })
        },
        // 跳转到订单列表页面
        goOrderListPages() {
            this.$router.push({
                path: '/orderList'
            })
        }
    },
}